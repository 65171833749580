import Bloodhound from 'corejs-typeahead/dist/bloodhound.js';

const CATEGORY_KEY = 'nal-categories-boost';
const CACHE_KEY = 'nal-categories-boost-cache';

function loadCategories() {
    const route = window.nal.project === 'outlook' ? '/api/outlook/categories' : '/api/categories';

    window.nal.categories.locations = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value', 'matches'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        identify: (obj) => (obj.value),
        limit: 10,
        remote: {
            url: route + '/locations?query=%QUERY',
            prepare: function (query, settings) {
                settings.url = settings.url.replace('%QUERY', encodeURIComponent(query));
                return window.jwt.addHeaderToSettings(settings);
            },
        },
    });
    window.nal.categories.companies = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        limit: 10,
        remote: {
            url: route + '/companies?query=%QUERY',
            prepare: function (query, settings) {
                return window.jwt.addHeaderToSettings(settings);
            },
        },
    });
    window.nal.categories.locations.initialize();
    window.nal.categories.companies.initialize();

    function updateCategories(data) {
        window.nal.categories.careers = data.careers;
        window.nal.categories.industries = data.industries;
        window.nal.categories.nalIndustries = data.nalIndustries;
        window.nal.categories.nalCareers = data.nalCareers;

        window.nal.categories.functions = data.areas;
        window.nal.categories.classifications = data.classifications;
        window.nal.categories.types = data.companyTypes;
        window.nal.categories.languages = data.languages;
        window.nal.categories.sizes = data.teamSizes;
        window.nal.categories.locations.clear();
        window.nal.categories.locations.add(data.countries);
    }

    let cache;

    try {
        cache = localStorage && localStorage.getItem(CACHE_KEY);
    } catch {
        noop();
    }

    const categories = [
        'careers',
        'industries',
        'nalIndustries',
        'nalCareers',
        'areas',
        'classifications',
        'companyTypes',
        'languages',
        'teamSizes',
        'countries',
    ];

    const localStorageData = localStorage && JSON.parse(localStorage.getItem(CATEGORY_KEY));

    if (localStorageData && cache && cache === window.nal.categoriesCache) {
        const undefinedCategories = categories.filter(category => {
            return !localStorageData[category] || !localStorageData[category].length;
        });

        // If all categories are defined, we can use the localStorage data
        if (undefinedCategories.length === 0) {
            updateCategories(localStorageData);
            return;
        }
    }

    window.jwt.handleOutlookRequest();

    $.ajax(route, {
        async: false,
    }).done(data => {
        updateCategories(data);
        try {
            if (localStorage) {
                try {
                    localStorage.setItem(CATEGORY_KEY, JSON.stringify(data));
                    localStorage.setItem(CACHE_KEY, window.nal.categoriesCache);
                } catch (e) {
                    localStorage.removeItem(CATEGORY_KEY);
                    localStorage.removeItem(CACHE_KEY);
                }
            }
        } catch {
            noop();
        }
    }).fail((jqXHR, ajaxSettings, thrownError) => {
        window.notify(trans('nal-lang.error.error-detected'), 'alert');
        if (!window.Sentry) {
            return;
        }
        window.Sentry.captureMessage(thrownError || jqXHR.statusText, {
            extra: {
                type: ajaxSettings.type,
                url: ajaxSettings.url,
                data: ajaxSettings.data,
                status: jqXHR.status,
                error: thrownError || jqXHR.statusText,
                response: jqXHR.responseText && jqXHR.responseText.substring(0, 100),
            },
        });
    });
}
export default loadCategories;
